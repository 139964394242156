import React from "react";
import { Icon } from "semantic-ui-react";
import { find } from "lodash";
import { connect } from "react-redux";
import ProductLink from "../ProductLink";
import styles from "./styles.module.scss";

class Footer extends React.Component {
    state = {
        cookie: false,
        pid: parseInt(localStorage.getItem("pid") || 1),
        product: false
    };

    componentDidMount = () => {
        const { products } = this.props;
        const product = find(products, { id: this.state.pid });
        this.setState({ product });
    };

    // --- render
    render() {
        const { categories } = this.props;
        const { pid, product } = this.state;
        let isSwitch = window.location.pathname === "/switch";

        if (isSwitch) {
            return null;
        }

        return (
            <div style={{ paddingBottom: 50 }}>
                {!isSwitch && (
                    <div className="cont">
                        <p className={styles.tagline}>
                            {product && product.section_id === 6 ? 'GET INSIGHTS INTO THE COMPETITIVE LANDSCAPE' : 'EXPLORE OUR LEARNING RESOURCE'}: CLICK THE BOXES TO
                            TAKE THE TOUR
                        </p>
                        <div className={styles.container}>
                            {categories.map(c => (
                                <ProductLink
                                    key={`cat-${c.id}`}
                                    className={styles.block}
                                    to={`/category/${
                                        c.children && c.children.length
                                            ? c.children[0].full_url
                                            : c.full_url
                                    }`}
                                >
                                    {c.icon1_path === '' && c.icon2_path === '' && <img
                                        src={`/${c.icon}-white.png`}
                                        alt=""
                                    />}
                                    {c.icon1_path !== '' && <img
                                        src={c.icon1_path}
                                        alt=""
                                        className={styles.logo}
                                    />}
                                    <span>{c.name.replace("<br>", " ")}</span>
                                    <Icon name="chevron right" />
                                </ProductLink>
                            ))}
                        </div>
                    </div>
                )}
                {!isSwitch && (
                    <div className="cont">
                        <ul className={styles.menu}>
                            <li>
                                <a
                                    href="https://www.amrytpharma.com/privacy/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Privacy
                                </a>
                            </li>
                            <li>
                                <ProductLink
                                    to={`/sitemap`}
                                    rel="noopener noreferrer"
                                >
                                    Sitemap
                                </ProductLink>
                            </li>
                            <li>
                                <a
                                    href="https://www.amrytpharma.com/cookie-policy/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Cookie Policy
                                </a>
                            </li>
                        </ul>
                    </div>
                )}

                <div className={styles.adverse}>
                    <div className="cont">
                        If you receive a report of an Adverse event, please report following your local process. You may also report directly to Chiesi here: <a href="https://www.chiesi.com/en/pharmacovigilance/">Pharmacovigilance | Chiesi Farmaceutici S.p.A.</a>
                    </div>
                </div>
                <div className="section--alt">
                    <div className="cont">
                        <div className={styles.textFlex}>
                            <p style={{ flexBasis: "49%", marginRight: "1%" }}>
                                The content on the ChiesiRARE Academy is intended for internal educational purposes and the content must not be used externally without obtaining appropriate local approval. 
                                <br />
                                <br />
                                {/* {pid === 1 && <span>LOM/2019/EU/017 - Date of preparation: 11th</span>} */}
                                {/* {pid === 4 && <span>MYA/2020/US - Date of preparation: 1st September 2020</span>} */}
                            </p>
                            <p
                                style={{
                                    flexBasis: "49%",
                                    margin: "0 0 0 1%",
                                    padding: "0 0 0 20px"
                                }}
                            >
                                All materials used in activities and interactions must be reviewed and approved for external use in accordance with applicable local procedural documents prior to their use.
                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.titleBar}>
                    <div className={styles.otherHalf} />
                    <div className="cont">
                        <div className={styles.titleBarInner}>
                            <div className={styles.titleBarLogo}>
                                Learning resource
                            </div>
                        </div>
                    </div>
                </div>
                {!localStorage.getItem("cookie") &&
                    this.state.cookie === false && (
                        <div className={styles.cookie}>
                            <div className="cont">
                                This site uses cookies as described in our
                                privacy statement to improve your experience. To
                                see what cookies we use and set your own
                                preferences please review the cookie notice in
                                our Privacy Policy. You may opt out if you wish.
                                By continuing to surf this website you
                                explicitly agree to the use of cookies on this
                                website. To configure permissions in more detail
                                please{" "}
                                <a
                                    href="https://www.amrytpharma.com/cookie-policy/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    click here
                                </a>
                                .<br />
                                <span
                                    onClick={() => {
                                        localStorage.setItem("cookie", 1);
                                        this.setState({ cookie: true });
                                    }}
                                >
                                    OK
                                </span>
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}

export default connect(state => ({
    products: state.product.products
}))(Footer);
