import React from "react";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { find } from "lodash";
import styles from "./styles.module.scss";
import TitleBar from "../../../components/front/TitleBar";
import ProductLink from "../../../components/front/ProductLink";
import Slider from "react-slick";

class HomepageView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            product: false
        };
    }

    componentDidMount = () => {
        const { products } = this.props;
        const product = find(products, {
            id: parseInt(localStorage.getItem("pid") || 1)
        });
        this.setState({ product });
    };

    // --- render
    render() {
        const { user } = this.props;
        const { product } = this.state;
        
        if (!user) {
            return null;
        }

        const settings = {
            arrows: true,
            autoplay: true,
            autoplaySpeed: 6000,
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        return (
            <div>
                <TitleBar colorIndex={product && product.section_id === 6 ? 6 : 5} />

                {product && product.slides && product.slides.length > 0 && (
                    <div className="cont" style={{ marginBottom: 30 }}>
                        <Slider {...settings}>
                            {product.slides.map((slide) => (
                                <div key={`slide-${slide.id}`}>
                                    {slide.link && slide.link.includes('http') ? (
                                        <a
                                            href={slide.link || '/'}
                                            target="_blank"
                                            rel="noopener norefer"
                                        >
                                            <img
                                                src={slide.image}
                                                alt=""
                                                style={{ width: "100%" }}
                                            />
                                        </a>
                                    ) : (
                                        <Link to={slide.link || '/'}>
                                            <img
                                                src={slide.image}
                                                alt=""
                                                style={{ width: "100%" }}
                                            />
                                        </Link>
                                    )}
                                </div>
                            ))}
                        </Slider>
                    </div>
                )}

                {/* {product && product.id === 1 && (
                    <div className="cont" style={{ marginBottom: 30 }}>
                        <Slider {...settings}>
                            <div>
                                <ProductLink to="/category/objection-handlers/reference-pack/policy-level">
                                    <img
                                        src="/slide5.jpg"
                                        alt=""
                                        style={{ width: "100%" }}
                                    />
                                </ProductLink>
                            </div>
                            <div>
                                <ProductLink to="/category/core-resources/meetings-resources/eas-symposium-28th-may-2019">
                                    <img
                                        src="/slide3.jpg"
                                        alt=""
                                        style={{ width: "100%" }}
                                    />
                                </ProductLink>
                            </div>
                            <div>
                                <ProductLink to="/category/hofh-library">
                                    <img
                                        src="/slide4.jpg"
                                        alt=""
                                        style={{ width: "100%" }}
                                    />
                                </ProductLink>
                            </div>
                            <div>
                                <ProductLink to="/category/news-updates">
                                    <img
                                        src="/slide1.png"
                                        alt=""
                                        style={{ width: "100%" }}
                                    />
                                </ProductLink>
                            </div>
                            <div>
                                <ProductLink to="/category/core-resources/meetings-resources/navigating-the-labyrinth-april-2016">
                                    <img
                                        src="/slide2.png"
                                        alt=""
                                        style={{ width: "100%" }}
                                    />
                                </ProductLink>
                            </div>
                        </Slider>
                    </div>
                )}

                {product && product.id === 2 && (
                    <div className="cont" style={{ marginBottom: 30 }}>
                        <Slider {...settings}>
                            <div>
                                <img
                                    src="/juxa-1.jpeg"
                                    alt=""
                                    style={{ width: "100%" }}
                                />
                            </div>
                            <div>
                                <ProductLink to="/category/core-resources/meetings-resources/navigating-the-labyrinth-april-2016">
                                    <img
                                        src="/slide3.jpg"
                                        alt=""
                                        style={{ width: "100%" }}
                                    />
                                </ProductLink>
                            </div>
                            <div>
                                <ProductLink to="/category/library">
                                    <img
                                        src="/slide4.jpg"
                                        alt=""
                                        style={{ width: "100%" }}
                                    />
                                </ProductLink>
                            </div>
                            <div>
                                <ProductLink to="/category/news-updates">
                                    <img
                                        src="/slide1.png"
                                        alt=""
                                        style={{ width: "100%" }}
                                    />
                                </ProductLink>
                            </div>
                            <div>
                                <ProductLink to="/category/objection-handlers/reference-pack/policy-level">
                                    <img
                                        src="/slide5.jpg"
                                        alt=""
                                        style={{ width: "100%" }}
                                    />
                                </ProductLink>
                            </div>
                        </Slider>
                    </div>
                )}

                {product && product.id === 3 && (
                    <div className="cont" style={{ marginBottom: 30 }}>
                        <Slider {...settings}>
                            <div>
                                <ProductLink to="/category/core-resources/patient-materials/other">
                                    <img
                                        src="/mslide1.jpg"
                                        alt=""
                                        style={{ width: "100%" }}
                                    />
                                </ProductLink>
                            </div>
                            <div>
                                <ProductLink to="/category/core-resources/hcp-materials/disease-awareness">
                                    <img
                                        src="/mslide2.jpg"
                                        alt=""
                                        style={{ width: "100%" }}
                                    />
                                </ProductLink>
                            </div>
                            <div>
                                <ProductLink to="/category/core-resources/hcp-materials/commercial-materials">
                                    <img
                                        src="/mslide3.jpg"
                                        alt=""
                                        style={{ width: "100%" }}
                                    />
                                </ProductLink>
                            </div>
                            <div>
                                <ProductLink to="/category/core-resources/meetings-resources/ece-2019">
                                    <img
                                        src="/mslide4.jpg"
                                        alt=""
                                        style={{ width: "100%" }}
                                    />
                                </ProductLink>
                            </div>
                        </Slider>
                    </div>
                )}

                {product && product.id === 4 && (
                    <div className="cont" style={{ marginBottom: 30 }}>
                        <Slider {...settings}>
                            <div>
                                <img
                                    src="/myalept-banner.jpeg"
                                    alt=""
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </Slider>
                    </div>
                )}

                {product && (product.id === 5 || product.id === 6) && (
                    <div className="cont" style={{ marginBottom: 30 }}>
                        <Slider {...settings}>
                            <div>
                                <img
                                    src="/slidecomm.png"
                                    alt=""
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </Slider>
                    </div>
                )} */}

                <div className="section--alt">
                    <div className="cont" style={{ textAlign: "center" }}>
                        {product && product.section_id === 6 ? (<>
                            <h2 className={styles.title}>
                                OUR APPROACH
                            </h2>
                            <h3 className={styles.subtitle} style={{ maxWidth: 768, marginLeft: 'auto', marginRight: 'auto' }}>
                                {product && product.disclaimer}
                            </h3>
                        </>) : (<>
                            <h3 className={styles.subtitle}>
                                {product && product.disclaimer}
                            </h3>
                            <h2 className={styles.title}>
                                Welcome to {product && product.name} library <br /> and
                                learning resource.
                                {/* <small>
                                    The {product.name} library and learning resource.
                                </small> */}
                            </h2>
                            <ul className={styles.quotes}>
                                <li>View videos</li>
                                <li>Consult articles</li>
                                <li>
                                    Download poster summaries..{" "}
                                    <small>and so much more</small>
                                </li>
                            </ul>
                            <p className={styles.content}>
                            The content on the ChiesiRARE Academy is intended for internal educational purposes and the content must not be used externally without obtaining appropriate local approval.
                            </p>
                        </>)}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(state => ({
    user: state.user.current,
    products: state.product.products
}))(HomepageView);
