import { createAction, handleActions } from 'redux-actions';

const defaults = {
    current: false,
};

export const actions = {
    updateCurrent: createAction('UPDATE_CURRENT'),
};

export default handleActions(
    {
        /**
         * Set current video
         */
        UPDATE_CURRENT: (state, data) => ({
            ...state,
            current: data.payload,
        }),
    },
    defaults
);
