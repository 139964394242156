import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import request from '../../../utils/request';
import { colors } from "../../../utils/constants";
import styles from "./styles.module.scss";
import TitleBar from "../../../components/front/TitleBar";

const SwitchView = ({ products }) => {
    const [ sections, setSections ] = useState([]);
    const [ section, setSection ] = useState();

    useEffect(() => {
        request('sections').then(({ data }) => {
            setSections(data.data);
        });
    }, []);

    return (
        <>
            <TitleBar isSwitch colorIndex={7} />
            <div className={styles.shape} style={{ backgroundImage: 'url(/intro-bg.jpg)' }}>
                <div>
                    <div>
                        <svg width="100%" height="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
                            <polygon points="0,0 100,0, 0,100" style={{fill:'#cb0067'}}></polygon>
                        </svg>
                    </div>
                    <img src="/chiesi-white.jpg" alt="Chiesi" />
                </div>
            </div>
            <div className={styles.container}>
                {section && (
                    <div style={{ marginBottom: -208 }}>
                        <div
                            className={`${styles.product} ${styles.section}`}
                            onClick={e => {
                                setSection(undefined);
                                return e.preventDefault();
                            }}
                        >
                            <p>Back to sections</p>
                        </div>
                    </div>
                )} 
                <div>
                    {section ? products.filter((o) => o.section_id === section.id).map((p) => (
                        <div
                            key={`product-${p.id}`}
                            className={`${styles.product} ${styles.section}`}
                            onClick={(e) => {
                                localStorage.setItem("pid", p.id);
                                window.location.href = `/${p.slug}`;
                                return e.preventDefault();
                            }}
                            style={{ borderColor: colors[section.id % colors.length].primary}}
                        >
                            <div>{p.name}</div>
                            <p>Learn now</p>
                            <Icon name="chevron right" />
                        </div>
                    )) : sections.map((o) => (
                        <div key={`section-${o.id}`}>
                            <div
                                className={`${styles.product} ${styles.section}`}
                                onClick={e => {
                                    setSection(o);
                                    return e.preventDefault();
                                }}
                                style={{ borderColor: colors[o.id % colors.length].primary}}
                            >
                                <div>{o.name}</div>
                                <p>Learn now</p>
                                <Icon name="chevron right" />
                            </div>
                        </div>
                    ))}
                </div>
                {/* <div className={styles.grid}>
                    <div className={styles.col}>
                        <div className={styles.intro}>
                            <h1>Welcome to<br />Chiesi Academy</h1>
                            <p>Explore our learning resources by clicking the options:</p>
                        </div>
                    </div>
                    <div className={styles.col}>
                        <div>
                            {section ? products.filter((o) => o.section_id === section.id).map((p) => (
                                <div
                                    key={`product-${p.id}`}
                                    className={`${styles.product} ${styles.section}`}
                                    onClick={(e) => {
                                        localStorage.setItem("pid", p.id);
                                        window.location.href = `/${p.slug}`;
                                        return e.preventDefault();
                                    }}
                                >
                                    <div>
                                        {p.name}
                                        <small>
                                            {p.slug === "lojuxta-lomitapide" || p.slug === "myalepta-metreleptin" ? <div className={styles.special}>▼</div> : false}
                                        </small>
                                    </div>
                                    <p>Learn now</p>
                                </div>
                            )) : sections.map((o) => (
                                <div key={`section-${o.id}`}>
                                    <div
                                        className={`${styles.product} ${styles.section}`}
                                        onClick={e => {
                                            setSection(o);
                                            return e.preventDefault();
                                        }}
                                    >
                                        <div>{o.name}</div>
                                        <p>Learn now</p>
                                    </div>
                                </div>
                            ))}
                            {section && (
                                <div>
                                    <div
                                        className={`${styles.product} ${styles.section}`}
                                        onClick={e => {
                                            setSection(undefined);
                                            return e.preventDefault();
                                        }}
                                    >
                                        <p>Back to sections</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div> */}
            </div>
            <div className={styles.footer}>
                <div className={styles.footerBackground}>
                    <div className={styles.black} />
                </div>
                <div className={styles.grid}>
                    <div className={styles.col}>
                        <div className={styles.left}>
                            <p>If you receive a report of an Adverse event, please report following your local process. You may also report directly to Chiesi here: <a href="https://www.chiesi.com/en/pharmacovigilance/">Pharmacovigilance | Chiesi Farmaceutici S.p.A.</a></p>
                        </div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.right}>
                            <p>The content on the ChiesiRARE Academy is intended for internal educational purposes and the content must not be used externally without obtaining appropriate local approval. 
                            <br /><br />
                            All materials used in activities and interactions must be reviewed and approved for external use in accordance with applicable local procedural documents prior to their use.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={styles.container}>
                <h1>Welcome to Chiesi-academy.com</h1>
                <h2>
                    Explore our learning resources: click the boxes to select
                    your product
                </h2>
                <div className={styles.row}>
                    <Shape />
                    <div
                        className={styles.products}
                        style={section && products.filter((o) => o.section_id === section.id) > 9 ?
                            { gridTemplateColumns: 'repeat(4, 200px)' }
                            : (section ? { gridTemplateRows: `repeat(${products.filter((o) => o.section_id === section.id).length > 3 ? 2 : 1}, 1fr)` } : { gap: 16 })
                        }
                    >
                        {section ? products.filter((o) => o.section_id === section.id).map((p, index) => (
                            p.logo ? (
                                <div
                                    style={{ backgroundColor: COLORS[index] }}
                                    className={`${styles.product} ${styles.section}`}
                                    onClick={(e) => {
                                        localStorage.setItem("pid", p.id);
                                        window.location.href = `/${p.slug}`;
                                        return e.preventDefault();
                                    }}
                                >
                                    <img src={p.logo} alt="" />
                                </div>
                            ) : (
                                <Product
                                    key={`Product__${p.id}`}
                                    color={COLORS[index]}
                                    {...p}
                                />
                            )
                        )) : sections.map((o) => (
                            <div key={`section-${o.id}`}>
                                <div
                                    style={{ backgroundColor: '#0d0f24' }}
                                    className={`${styles.product} ${styles.section}`}
                                    onClick={e => {
                                        setSection(o);
                                        return e.preventDefault();
                                    }}
                                >
                                    {o.logo ? <img src={o.logo} alt="" /> : o.name}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <p>
                    The content on the ChiesiRARE Academy is intended for internal educational purposes and the content must not be used externally without obtaining appropriate local approval.
                </p>
            </div> */}
        </>
    );
};

const Product = ({ disclaimer, name, id, slug, color }) => {
    let names = name.replace('Birch ', 'Birch-').replace('& ', '&-').split(" ");
    let isSpecial = slug === "lojuxta-lomitapide" || slug === "myalepta-metreleptin";
    return (
        <Link
            style={{ backgroundColor: color }}
            className={styles.product}
            to={`/${slug}`}
            onClick={e => {
                localStorage.setItem("pid", id);
                window.location.href = `/${slug}`;
                return e.preventDefault();
            }}
        >
            <div className={styles.logo}>
                <h3>
                    {names[0]} <small>&reg;</small>
                </h3>
                {names.length > 1 ? <span>{names[1].replace('Birch-', 'Birch ').replace('&-', '& ')}</span> : false}
                {isSpecial ? <div className={styles.special}>▼</div> : false}
            </div>

            <div className={styles.angle}>
                <Icon name="chevron right" />
            </div>

            <p>
                {disclaimer}
                {/* This site is intended for employees in{" "}
                <span className="region">{region}</span> */}
            </p>
        </Link>
    );
};

export default connect(state => ({
    products: state.product.products
}))(SwitchView);
