export const locales = [
    { key: 'en', text: 'UK', value: 'en' },
    { key: 'nl', text: 'Netherlands', value: 'nl' },
    { key: 'de', text: 'Germany', value: 'de' },
    { key: 'es', text: 'Spain', value: 'es' },
    { key: 'it', text: 'Italy', value: 'it' },
    { key: 'gr', text: 'Greece', value: 'gr' },
    { key: 'ar', text: 'Saudi & GCC', value: 'ar' },
    { key: 'tr', text: 'Turkey', value: 'tr' },
    { key: 'no', text: 'Norway', value: 'no' },
    { key: 'se', text: 'Sweden', value: 'se' },
    { key: 'at', text: 'Austria', value: 'at' },
    { key: 'il', text: 'Israel', value: 'il' },
    { key: 'fr', text: 'France', value: 'fr' },
    { key: 'da', text: 'Denmark', value: 'da' },
    { key: 'fi', text: 'Finland', value: 'fi' },
    { key: 'pt', text: 'Portugal', value: 'pt' },
    { key: 'np', text: 'Non-promotional', value: 'np' },
];

export const colors = [
    {
        primary: '#563C82',
    },
    {
        primary: '#97B9C9',
    },
    {
        primary: '#B90066',
    },
    {
        primary: '#464E7E',
    },
    {
        primary: '#8CAC89',
    },
    {
        primary: '#428BA6',
    },
    {
        primary: '#0d0f24',
    },
    {
        primary: '#acacac',
    },
];
