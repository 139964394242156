import { createAction, handleActions } from 'redux-actions';

const defaults = {
    products: [],
};

export const actions = {
    setProducts: createAction('SET_PRODUCTS'),
};

export default handleActions(
    {
        /**
         * Set current video
         */
        SET_PRODUCTS: (state, data) => ({
            ...state,
            products: data.payload,
        }),
    },
    defaults
);
