import request from '../utils/request';

/**
 * Get category by URL
 */
export const getCategoryByUrl = (url) => {
    return request(`categories/${url}`);
};

/**
 * Get parent categories
 */
export const getParentCategories = () => {
    return request('categories?all=1');
};
