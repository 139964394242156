import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Form, Header, Segment } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import { Editor } from '@hugerte/hugerte-react';
import { recursive } from '../../../../utils/category';
import history from '../../../../utils/history';
import request from '../../../../utils/request';

const { Button, Group, Input, Select } = Form;

class AdminUsersListView extends React.Component
{
    state = {
        errors: {},
        faq: {categories: []},
        categories: [],
    };

    /**
     * Mount
     */
    componentDidMount = () => {
        request(`admin/categories?all=1`).then(({ data }) => {
            this.setState({
                categories: recursive(data),
            });
        });

        if (this.props.match.params.id) {
            this.fetch();
        }
    }

    /**
     * Fetch
     */
    fetch = () => {
        request(`admin/faqs/${this.props.match.params.id}?with_categories=1`).then(({ data }) => {
            data.categories = data.categories.map(c => c.id);

            this.setState({
                faq: data,
            });
        });
    }

    /**
     * Handle input
     */
    handleInput = (e, { name, value }) => {
        const { errors, faq } = this.state;

        this.setState({
            errors: {
                ...errors,
                [name]: false,
            },
            faq: {
                ...faq,
                [name]: value,
            },
        });
    }

    /**
     * Save
     */
    handleSubmit = () => {
        const { id } = this.props.match.params;
        const { faq } = this.state;

        request[id ? 'patch' : 'post'](`admin/faqs${id ? `/${id}` : ''}`, faq).then(() => {
            toast({
                type: 'success',
                icon: '',
                description: 'FAQ saved succesfully',
                time: 2500,
            });
            history.push('/admin/faqs');
        }).catch((error) => {
            toast({
                type: 'error',
                icon: '',
                title: 'Something went wrong',
                description: 'Please check all required fields',
                time: 2500,
            });

            this.setState({
                ...this.state,
                errors: error.response.data.errors,
            });
        });
    }

    // --- render
    render() {
        const {errors, categories, faq } = this.state;

        if (this.props.match.params.id && !faq.id) {
            return null;
        }

        return (
            <Container className="admin-page">
                <Segment padded>
                    <div className="admin-page-header">
                        <Header as="h1">{faq.id ? 'Edit' : 'Create'} FAQ</Header>
                        <Link to="/admin/faqs">Back</Link>
                    </div>
                    <Form onSubmit={this.handleSubmit}>
                        {categories.length ? (
                            <Select
                                error={errors.categories && errors.categories !== false}
                                label="Categories"
                                name="categories"
                                options={categories}
                                onChange={this.handleInput}
                                value={faq.categories}
                                search
                                multiple selection
                                required
                            />
                        ) : null}
                        <Input
                            error={errors.question && errors.question !== false}
                            label="Question"
                            name="question"
                            onChange={this.handleInput}
                            required
                            value={faq.question}
                        />
                        <Form.Field required>
                            <label>Answer</label>
                            <Editor
                                onEditorChange={(content) => {
                                    this.handleInput(undefined, {
                                        name: 'answer',
                                        value: content,
                                    });
                                }}
                                value={faq.answer}
                                init={{
                                    plugins: 'link lists',
                                    menubar: false,
                                    statusbar: false,
                                    toolbar: 'undo redo | bold italic | bullist numlist | alignleft aligncenter alignright | link',
                                }}
                            />
                        </Form.Field>
                        <Group>
                            <Button primary>Save</Button>
                            <Link to="/admin/faqs" style={{ marginTop: 9, marginLeft: 10 }}>Cancel</Link>
                        </Group>
                    </Form>
                </Segment>
            </Container>
        );
    }
}

export default AdminUsersListView;
