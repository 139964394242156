import { createAction, handleActions } from 'redux-actions';

const defaults = {
    status: false,
};

export const actions = {
    setStatus: createAction('SET_STATUS'),
};

export default handleActions(
    {
        /**
         * Set current video
         */
        SET_STATUS: (state, data) => ({
            ...state,
            status: data.payload,
        }),
    },
    defaults
);
