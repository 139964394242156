import React from 'react';
import { Redirect } from 'react-router-dom';
import { Form, Icon, Message } from 'semantic-ui-react';
import request from '../../../utils/request';
import styles from './styles.module.scss';
import TitleBar from '../../../components/front/TitleBar';

class LoginView extends React.Component
{
    state = {
        emailAddress: '',
        error: false,
        errors: {
            emailAddress: false,
            password: false,
        },
        password: '',
        passwordConfirm: '',
        forgot: false,
        reset: false,
    };

    /**
     * Mount
     */
    componentDidMount = () => {
        if (window.location.search === '') {
            this.emailField.focus();
        } else {
            this.setState({
                reset: true,
            })
        }
    }

    /**
     * Handle input
     */
    handleInput = (e, { name, value }) => {
        const { errors } = this.state;

        this.setState({
            [name]: value,
            errors: {
                ...errors,
                [name]: false,
            },
        });
    }

    /**
     * Handle submit
     */
    handleSubmit = () => {
        const { emailAddress, password, forgot, passwordConfirm, reset } = this.state;

        this.setState({
            errors: {
                emailAddress: emailAddress === '',
                password: password === '',
            },
        });

        if (reset && password) {
            request.patch(`auth/reset-password${window.location.search}`, {
                password,
                password_confirmation: passwordConfirm,
            }).then(() => {
                window.location = '/login';
            });
        } else if (forgot && emailAddress) {
            request.post('auth/forgot-password', {
                email_address: emailAddress,
            }).then(() => {
                alert('A new password has been sent');
                this.setState({
                    forgot: false,
                    errors: {
                        emailAddress: false,
                        password: false,
                    }
                })
            });
        } else if (emailAddress && password) {
            request.post('auth/login', {
                email_address: emailAddress,
                password: password,
            }).then(({ data }) => {
                localStorage.setItem('tokens', JSON.stringify(data));
                window.location = '/switch';
            }).catch(({ error }) => {
                this.setState({
                    error: true,
                });
            });
        }
    }

    // --- render
    render() {
        const { emailAddress, error, errors, password, forgot, reset, passwordConfirm } = this.state;

        if (localStorage.getItem('tokens')) {
            return <Redirect to="/" />
        }

        return (
            <div className={styles.container}>
                <div className={styles.formContainer}>
                    <img src="/chiesi.jpg" alt="" style={{ height: 54, marginBottom: 15 }} />
                    <TitleBar colorIndex={5} small logo={false} />
                    {reset ? (<div style={{ margin: '20px 0 25px 0' }}>
                        Welcome to Chiesi Academy, this is an internal website for Chiesi employees only. Please fill in a password to activate your personal account. Afterwards you are able to login with your new password.
                    </div>) : (<div style={{ margin: '20px 0 25px 0' }}>
                        Welcome to Chiesi Academy, this is an internal website for Chiesi employees only. Please provide your email address and password.
                    </div>)}
                    <Form className={styles.form} onSubmit={this.handleSubmit}>
                        {error ? (
                            <Message
                                error
                                content='Incorrect credentials'
                            />
                        ) : null}
                        {reset ? null : (
                            <Form.Input
                                error={errors.emailAddress}
                                iconPosition='left'
                                name="emailAddress"
                                onChange={this.handleInput}
                                placeholder='Email address'
                                value={emailAddress}
                            >
                                <Icon name='envelope' />
                                <input ref={(c) => { this.emailField = c; }} />
                            </Form.Input>
                        )}
                        {forgot ? null : (
                            <Form.Input
                                error={errors.password}
                                iconPosition='left'
                                name="password"
                                onChange={this.handleInput}
                                placeholder='Password'
                                type="password"
                                value={password}
                            >
                                <Icon name='lock' />
                                <input />
                            </Form.Input>
                        )}
                        {reset ? (
                            <Form.Input
                                error={errors.password}
                                iconPosition='left'
                                name="passwordConfirm"
                                onChange={this.handleInput}
                                placeholder='Confirm password'
                                type="password"
                                value={passwordConfirm}
                            >
                                <Icon name='lock' />
                                <input />
                            </Form.Input>
                        ) : null}
                        <div className={styles.buttons}>
                            <button className={styles.button} type="submit">
                                {forgot ? 'Reset' : ''}
                                {reset ? 'Set password' : ''}
                                {!reset && !forgot ? 'Login' : ''}
                                <span><Icon name="chevron right" /></span>
                            </button>
                            {forgot ? (
                                <span style={{ textDecoration: 'underline', marginLeft: 15, cursor: 'pointer' }} onClick={() => this.setState({ forgot: false })}>Cancel</span>
                            ) : (
                                <span style={{ textDecoration: 'underline', marginLeft: 15, cursor: 'pointer' }} onClick={() => this.setState({ forgot: true })}>Forgot password?</span>
                            )}
                        </div>
                    </Form>
                </div>
            </div>
        );
    }
}

export default LoginView;
