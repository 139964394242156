import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Container, Header, Input, Segment, Table } from 'semantic-ui-react';
import { toast } from 'react-semantic-toasts';
import request from '../../../../utils/request';
import tableStyles from '../../../../styles/table.module.scss';
import DeleteAction from '../../../../components/admin/DeleteAction';
import Pagination from '../../../../components/admin/Pagination';

const { Cell, HeaderCell, Row} = Table;
let timer = 0;

class AdminSectionsListView extends React.Component
{
    state = {
        isLoading: false,
        query: '',
        pagination: {},
        sections: [],
        confirmDelete: false,
    };

    /**
     * Mount
     */
    componentDidMount = () => {
        this.fetch();
    }

    /**
     * Delete tag
     */
    deleteSection = (tag) => {
        request.delete(`admin/sections/${tag.id}`).then(() => {
            this.fetch();

            toast({
                type: 'success',
                icon: '',
                description: 'Section deleted succesfully',
                time: 2500,
            });
        });
    }

    /**
     * Fetch
     */
    fetch = () => {
        request(`admin/sections?q=${this.state.query}`).then(({ data }) => {
            this.setState({
                isLoading: false,
                pagination: data.meta,
                sections: data.data,
            });
        });
    }

    /**
     * Search
     */
    searchSections = (query) => {
        clearTimeout(timer);

        this.setState({
            isLoading: true,
            query,
        });

        timer = setTimeout(() => { this.fetch(); }, 300);
    }

    // --- render
    render() {
        const { isLoading, pagination, query, sections } = this.state;

        return (
            <Container className="admin-page">
                <Segment padded>
                    <div className="admin-page-header">
                        <Header as="h1">Sections</Header>
                        <Link to="/admin/sections/create">
                            <Button primary icon="plus" />
                        </Link>
                    </div>
                    <Input
                        fluid
                        loading={isLoading}
                        placeholder='Search...'
                        onChange={(e) => { this.searchSections(e.target.value); }}
                        value={query}
                    />
                    <Table>
                        <Table.Header>
                            <Row>
                                <HeaderCell>Section</HeaderCell>
                                <HeaderCell singleLine textAlign="right">Actions</HeaderCell>
                            </Row>
                        </Table.Header>
                        <Table.Body>
                            {sections.map((section) => (
                                <Row key={section.id}>
                                    <Cell>
                                        <Link to={`/admin/sections/${section.id}`}>
                                            {section.name}
                                        </Link>
                                    </Cell>
                                    <Cell collapsing textAlign="right">
                                        <Link to={`/admin/sections/${section.id}`}>
                                            <Button
                                                basic
                                                size="mini"
                                                icon="pencil"
                                            />
                                        </Link>
                                        <span className={tableStyles.actionsDivider}> | </span>
                                        <DeleteAction
                                            message="Are you sure you want to delete this section?"
                                            onDelete={() => { this.deleteSection(section); }}
                                        />
                                    </Cell>
                                </Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Segment>
                <Pagination data={pagination} onClick={this.fetch} />
            </Container>
        );
    }
}

export default AdminSectionsListView;
